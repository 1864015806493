<template lang="pug">
    .card-meta
        v-row(dense align="center")
            v-col(cols="12" md="6").py-0
                .first-seen
                    CardTimeAgo(:listing="listing")
            v-col(cols="12" md="6").px-3.text-center.text-md-right
                .source
                    span.label Source:
                    a(:href="listing.permalink") {{listing.sourceName}}
</template>

<script>
const CardTimeAgo = () => import("@/components/CardTimeAgo");
export default {
  name: "CardMeta",
  props: { listing: { type: Object, required: true } },
  components: { CardTimeAgo }
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/colors.scss";
.first-seen {
  position: relative;
}
.card-meta {
  background: $muted;
  font-size: 80%;
}
span.label {
  margin-right: 0.25rem;
  font-weight: bold;
}

.card-meta a {
  color: black;
  text-decoration: none;
}

.card-meta a:hover {
  color: $secondary;
}

.price-warning {
  padding: 0.2rem;
  color: black;
  font-size: 1.2rem;
}
</style>
